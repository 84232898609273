@import 'variables';

html,
body {
	height: 100%;
	width: 100%;
	font-size: 16px;
	letter-spacing: 0.3px;
	line-height: 1.6;
	background: $white;
	font-family: 'Open Sans', sans-serif !important;

	scroll-behavior: smooth;
}

[tabindex='-1']:focus {
	outline: none;
}

hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
	border: 0;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	height: 0;
}

select,
button,
textarea,
input {
	vertical-align: baseline;
}
div {
	box-sizing: border-box;
}
html,
body {
	&[dir='rtl'],
	&[dir='ltr'] {
		unicode-bidi: embed;
	}
}
bdo[dir='rtl'] {
	direction: rtl;
	unicode-bidi: bidi-override;
}
bdo[dir='ltr'] {
	direction: ltr;
	unicode-bidi: bidi-override;
}

img {
	max-width: 100%;
}
a,
a:focus,
a:hover {
	text-decoration: none;
	cursor: pointer;
}
blockquote {
	border-left: 2px solid $gray-200;
	padding-left: 1rem;
	margin-bottom: 1rem;
	font-size: 1.01625rem;
}
.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
	outline: none;
}
.o-hidden {
	overflow: hidden;
}
.separator-breadcrumb {
	margin-bottom: 2rem;
}
@for $i from 1 through 5 {
	.line-height-#{$i} {
		line-height: #{$i};
	}
}

mat-form-field {
  width: 100%;
}

.th{
    display: flex;
    flex-direction: row;
    align-items: center;
}

thead {
	border-bottom: 0.5px solid #A2A1A8;
}

.center{
  justify-content: center !important;
}

.titleDivider {
	display: flex;
	align-items: center;
	width: fit-content; 
	border-bottom: 3px solid #2D72FF; 
	padding-bottom: 3px; 
	color: #2D72FF;

	.titleIcon {
		font-size: larger; 
		color: #2D72FF;
	}

	.title {
		font-size: 15px;
	}
}

.boxShadow {
	box-shadow: 0px 0px 6px #00000029;
	border-radius: 15px;

	#headerTemplate {
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
		justify-content: space-between;
		gap: 0.8rem;
	}
}

.trash {
	color: red!important;
}

// .mat-input-element {
// 	caret-color: $primary;
// }

// .mat-form-field-appearance-outline.mat-focused.mat-form-field-outline-thick {
// 	color: $primary !important;
// }

// .mat-flat-button.mat-primary {
// 	background-color: $primary;
// 	color: $white;
// }


