$font-family-heading: 'Open Sans', sans-serif !important;
$font-family-base: 'Open Sans', sans-serif !important;
$font-size-base: 0.813rem;


$primary: #2D72FF !default;
$secondary: black !default;
$success: #10A200 !default;
$info: #FFF !default;
$warning: #FFF !default;
$danger: #FFF !default;
$light: #FFF !default;
$dark: #FFF !default;
$background: #FFF !default;
$foreground: #FFF !default;
$heading: #FFF;


$theme-colors: (
	primary: $primary,
	secondary: $secondary,
	success: $success,
	info: $info,
	warning: $warning,
	danger: $danger,
	light: $light,
) !default;

/**
BUTTONS
*/
$btn-primary-color: #FFF;
$btn-outline-secondary-color-hover: #FFF;
$btn-cancel-color: #FFF;
$btn-cancel-background-color: #FFF;
$btn-cancel-background-color-hover: #FFF;

$container-max-widths: (
	sm: 540px,
	md: 720px,
	lg: 960px,
	xl: 1170px
) !default;

// LAYOUT VARS
$topbar-height: 120px;
$topbar-height-mobile: 70px;

// sidebar background
$sidebar-width: 260px;
$sidebar-min-width: 62px;
$sidebar-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
$sidebar-background: #FFF;;
$sidebar-background-hover: #f2f2f2;
$sidebar-background-active: #FFF;
$sidebar-border-radius: 6px;

// sidebar color text
$sidebar-text-color: #FFF;
$sidebar-text-active-color: #FFF;
$sidebar-text-hover-color: #FFF;

// sidebar color icon
$sidebar-icon-color: #FFF;
$sidebar-icon-active-color: #FFF;
$sidebar-icon-hover-color: #FFF;

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/_breakpoints';
